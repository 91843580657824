<template>
  <div>
    <Modal v-model="ContractCreateModal" width="800">
      <Form
        ref="investmentContractModel"
        :model="investmentContractModel"
        autocomplete="on"
        name="oohforce-device-add"
        id="oohforce-device-add"
        :rules="ruleValidate"
        label-position="top"
      >
        <Row :gutter="8" class="m-b-10">
          <i-col span="24">
            <FormItem label="合同名称">
              <Input
                v-model="investmentContractModel.contractName"
                size="small"
                placeholder="请输入合同名称"
              />
            </FormItem>
          </i-col>

          <i-col span="8">
            <FormItem label="招商项目">
              <i-select
                v-model="investmentContractModel.investId"
                filterable
                size="small"
                placeholder="请选择招商项目"
              >
                <i-option
                  v-for="item in investmentArray"
                  :value="item.id"
                  :key="'st_' + item.id"
                  >{{ item.name }}</i-option
                >
              </i-select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="签约客户">
              <i-select
                v-model="investmentContractModel.orderNo"
                filterable
                size="small"
                placeholder="请选择签约客户"
              >
                <i-option
                  v-for="item in orderArray"
                  :value="item.orderNo"
                  :key="'st_' + item.orderNo"
                  >{{ item.userName }}</i-option
                >
              </i-select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="租赁用途">
              <i-select
                v-model="investmentContractModel.purposeType"
                filterable
                size="small"
                placeholder="请选择租赁用途"
              >
                <i-option
                  v-for="item in PurposeTypeArray"
                  :value="item.id"
                  :key="'st_' + item.id"
                  >{{ item.name }}</i-option
                >
              </i-select>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="租户类型">
              <RadioGroup v-model="investmentContractModel.type">
                <Radio label="10">企业</Radio>
                <Radio label="9">个人</Radio>
              </RadioGroup>
            </FormItem>
          </i-col>

          <i-col span="8">
            <FormItem
              :label="
                investmentContractModel.type === '10' ? '租户名称' : '承租人'
              "
            >
              <Input
                v-model="investmentContractModel.businessName"
                size="small"
                :placeholder="
                  '请输入' +
                  (investmentContractModel.type === '10'
                    ? '租户名称'
                    : '承租人')
                "
              />
            </FormItem>
          </i-col>
          <i-col span="8" v-if="investmentContractModel.type === '10'">
            <FormItem label="法人代表">
              <Input
                v-model="investmentContractModel.legalPerson"
                size="small"
                placeholder="请输入法人代表"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="联系电话">
              <Input
                v-model="investmentContractModel.tel"
                size="small"
                placeholder="请输入联系电话"
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem
              :label="
                investmentContractModel.type === '10'
                  ? '统一社会信用代码'
                  : '身份证号'
              "
            >
              <Input
                v-model="investmentContractModel.orgCode"
                size="small"
                :search="investmentContractModel.type === '10'"
                enter-button="自动录入"
                @on-search="writeOrgCode"
                :placeholder="
                  '请输入' +
                  (investmentContractModel.type === '10'
                    ? '统一社会信用代码'
                    : '身份证号')
                "
              />
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="详细地址">
              <Row :gutter="8">
                <i-col span="4">
                  <i-select
                    placeholder="省"
                    v-model="investmentContractModel.provinceCode"
                    clearable
                    size="small"
                    class="w-full"
                  >
                    <Option
                      v-for="(item, index) in provinceList"
                      :key="index"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </Option>
                  </i-select>
                </i-col>
                <i-col span="4">
                  <i-select
                    placeholder="市"
                    v-model="investmentContractModel.cityCode"
                    clearable
                    size="small"
                    class="w-full"
                  >
                    <Option
                      v-for="(item, index) in cityList"
                      :key="index"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </Option>
                  </i-select>
                </i-col>
                <i-col span="4">
                  <i-select
                    placeholder="区"
                    v-model="investmentContractModel.areaCode"
                    clearable
                    size="small"
                    class="w-full"
                  >
                    <Option
                      v-for="(item, index) in regionList"
                      :key="index"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </Option>
                  </i-select>
                </i-col>
                <i-col span="12">
                  <Input
                    type="text"
                    size="small"
                    v-model="investmentContractModel.address"
                    placeholder="请输入详细地址"
                  ></Input>
                </i-col>
              </Row>

            </FormItem>
          </i-col>

          <i-col span="24">
            <FormItem label="客户描述">
              <Input
                    type="text"
                    size="small"
                    v-model="investmentContractModel.description"
                    placeholder="请输入客户描述"
                  ></Input>
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div slot="footer">
      <Button type="text" class="m-r-5" @click="ContractCreateModal = false"
        >关闭</Button
      >
      <Button type="primary" @click="handleSubmit">确定</Button>
    </div>
    </Modal>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'

import { listDictLabel } from '@/api/contract/dict'
import {
  getProvinceList,
  getCityListByProvinceCode,
  getRegionListByCityCode
} from '@/api/sys/provincecityregion'

import { queryorgcodebycompanyfullname } from '@/api/scp/invoice'

/** new api */
import { changeProjectToContract, listInvestmentProject, listSinInBusiness } from '@/api/contract/invest'
export default {
  components: {},
  props: {},
  data () {
    return {
      ContractCreateModal: false,
      PurposeTypeArray: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      investmentArray: [],
      orderArray: [],

      rentStartDate: '',
      investmentContractModel: {
        type: '10',
        contractName: '',
        investId: null,
        areaCode: '',
        cityCode: '',
        provinceCode: '',
        orderNo: null,
        businessName: '',
        address: null,
        legalPerson: null,
        orgCode: null
      },
      ruleValidate: {}
    }
  },
  mounted () {},
  created () {

  },
  methods: {
    showModal () {
      this.getProvinceList()
      this.initInvestment()
      listDictLabel({ dictType: 'purpose_type' }).then((res) => {
        this.PurposeTypeArray = res

      // this.$store.commit('set_investment_PurposeTypeArray', res)
      })
      this.ContractCreateModal = true
    },
    initInvestment () {
      listInvestmentProject({ pageNumber: 1, pageSize: 66 }).then((response) => {
        this.investmentArray = response.records
      })
    },
    initOrderArray () {
      listSinInBusiness({ investId: this.investmentContractModel.investId }).then(res => {
        this.orderArray = res.filter((x) => x.orderStatus === 1)
      })
    },

    getProvinceList () {
      getProvinceList().then((res) => {
        this.provinceList = res
      })
    },
    getCityList () {
      const data = {
        provinceCode: this.investmentContractModel.provinceCode
      }
      getCityListByProvinceCode(data).then((res) => {
        this.cityList = res
      })
    },
    getRegionList () {
      const data = {
        cityCode: this.investmentContractModel.cityCode
      }
      getRegionListByCityCode(data).then((res) => {
        this.regionList = res
      })
    },

    writeOrgCode () {
      if (this.investmentContractModel.businessName) {
        queryorgcodebycompanyfullname({ companyName: this.investmentContractModel.businessName }).then(res => {
          if (res) {
            this.investmentContractModel.orgCode = res.organizationCode
          } else {
            this.$Notice.error({ desc: '未检索到社会统一信息代码，请确认租户名称是否正确或手动输入社会统一信息代码' })
          }
        })
      } else {
        this.$Notice.info({ desc: '您未输入公司名称！' })
      }
    },
    handleSubmit () {
      this.$refs.investmentContractModel.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '请确认信息填写无误？',
            onOk: () => {
              // if (this.investmentId) {
              //   this.investmentContractModel.id = this.investmentId
              // }
              // this.$store.commit('set_investment_pageType', 'list')
              // this.investmentContractModel.rentStartDate = ParseDate(
              //   this.rentStartDate
              // )
              // this.deviceIds = this.choiceDeviceIdArray.toString()
              changeProjectToContract(this.investmentContractModel).then(
                (res) => {
                  if (res && res.error) {

                  } else {
                    this.$Notice.success({ desc: '操作成功' })
                    this.$store.commit('set_contract_pageType', 'list')
                    this.$store.commit('set_contract_update', new Date())
                  }
                }
              )
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    },
    handleChoiceDevices () {
      this.$refs.deviceChoice.showModal()
    }
  },
  watch: {
    'investmentContractModel.provinceCode' (val) {
      if (val) {
        this.getCityList()
      } else {
        this.investmentContractModel.cityCode = ''
        this.investmentContractModel.areaCode = ''

        this.cityList = []
        this.regionList = []
      }
    },
    'investmentContractModel.cityCode' (val) {
      if (val) {
        this.getRegionList()
      } else {
        this.investmentContractModel.areaCode = ''
        this.regionList = []
      }
    },
    'investmentContractModel.investId' (val) {
      if (val) {
        this.initOrderArray()
      } else {
        this.investmentContractModel.orderNo = ''
        this.orderArray = []
      }
    }
  },
  computed: {}
}
</script>
